import $ from "jquery";
// import "popper.js"; // Bootstrap requires this
import * as bootstrap from "bootstrap";
import "../src/js/fslightbox";
import "slick-carousel";
import * as AOS from 'aos/dist/aos.js';

// import "../src/js/flow-scroll.js";

// Can import modules as needed
// ex. import 'bootstrap/js/dist/dropdown';
// import "./scss/style.scss"; // This is now being imported in the webpack.config file 

// # ---------------------------------------------------------------
// # Custom Methods...
(function ($) {

	AOS.init(); // Init AOS

	window.bootstrap = bootstrap; // This is for the modal triggered in book-online.php

	function announcementBannerSpacing() {
		// Check if header announcement banner is active, if so, add more padding
		if ($('body').hasClass('has-announcement-banner')) {

			const topBarHeight = $('.header-top-bar').outerHeight();

			// We will add the height of the announcement banner to the current padding-top of
			// the body, and then set the combined value to be the new padding top
			$('body').css('padding-top', (parseInt($('body').css('padding-top')) + topBarHeight));

			// Same thing with the hero curtain top text, because it does not get pushed down
			// on larger screens
			if ($(window).width() > 1280) {
				$('.home-block__hero-curtain .text--top').css('top', (parseInt($('.home-block__hero-curtain .text--top').css('top')) + topBarHeight));
			}
		}
	}

	$(function () {

		announcementBannerSpacing();

		$('.performance-calendar-event').on('click', function (e) {
			$('.performance-calendar-event').not(this).popover('hide');
		});

		// Initiate tooltips
		// Only on calendar page and large screens 
		if ($(window).width() > 1023) {

			if ($('.performance-calendar').length > 0) {
				var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
				var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
					return new bootstrap.Popover(popoverTriggerEl)
				})

				// Keep popovers open on hover
				$(".performance-calendar-event").popover({
					trigger: "manual",
					html: true,
					animation: false
				})
					.on("mouseenter", function () {
						var _this = this;
						$(this).popover("show");
						$(".popover").on("mouseleave", function () {
							$(_this).popover('hide');
						});
					}).on("mouseleave", function () {
						var _this = this;
						setTimeout(function () {
							if (!$(".popover:hover").length) {
								$(_this).popover("hide");
							}
						}, 300);
					});
			}
		} else {
			$(".performance-calendar-event").popover('disable');
		}

		// Toggle menu open class
		$('.navbar-toggler').click(function (e) {
			$('#header-navigation').toggleClass('nav-open');
			$('body').toggleClass('position-fixed');
			$('#site-header').toggleClass('nav-open');
		});

		// Header scrolling effects
		// Check scroll amount, show / hide header items accordingly
		// function checkScrolled() {
		// 	let $elem = $("header#site-header");
		// 	if ($elem.length) {
		// 		if ($(document).scrollTop() < 240) {
		// 			$elem.removeClass("scrolled");
		// 		} else {
		// 			$elem.addClass("scrolled");
		// 		}
		// 	}
		// }

		// //Shrink header on scroll OR page load
		// $(document).scroll(function () {
		// 	checkScrolled();
		// });

		// $(document).ready(function () {
		// 	checkScrolled();
		// });

		// Slick sliders
		// Text carousel
		// Image Carousel
		$('.image-carousel--slider').slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			infinite: true,
			arrows: true,
			dots: true,
			autoplay: true, // TODO Turn off autoplay if has video
			autoplaySpeed: 5000,
			speed: 2000,
			//centerMode: true,
			//variableWidth: true,
			//appendArrows: '.slick-nav',
			prevArrow:
				'<a class="slick-prev"><i class="bi bi-chevron-compact-left"></i></a>',
			nextArrow:
				'<a class="slick-next"><i class="bi bi-chevron-compact-right"></i></a>',
		});

		// Home Page Featured Shows
		$('.featured-shows--slider').slick({
			slidesToShow: 3,
			slidesToScroll: 1,
			infinite: true,
			arrows: true,
			dots: true,
			autoplay: false,
			centerMode: true,
			//centerMode: true,
			variableWidth: true,
			//appendArrows: '.slick-nav',
			prevArrow:
				'<a class="slick-prev"><i class="bi bi-chevron-compact-left"></i></a>',
			nextArrow:
				'<a class="slick-next"><i class="bi bi-chevron-compact-right"></i></a>',
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 2,
					}
				},
				{
					breakpoint: 391,
					settings: "unslick"
				}
			]
		});

		$('.presenters-slider-wrap').slick({
			slidesToShow: 6,
			slidesToScroll: 1,
			infinite: true,
			arrows: true,
			dots: false,
			autoplay: false,
			//centerMode: true,
			//variableWidth: true,
			//appendArrows: '.slick-nav',
			prevArrow:
				'<a class="slick-prev"><i class="bi bi-chevron-compact-left"></i></a>',
			nextArrow:
				'<a class="slick-next"><i class="bi bi-chevron-compact-right"></i></a>',
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 5,
					}
				},
				{
					breakpoint: 768,
					settings: "unslick"
				},

			]
		});

		$('.sponsor-slider-wrap').slick({
			slidesToShow: 10,
			slidesToScroll: 1,
			infinite: true,
			arrows: true,
			dots: false,
			autoplay: false,
			//centerMode: true,
			//variableWidth: true,
			//appendArrows: '.slick-nav',
			prevArrow:
				'<a class="slick-prev"><i class="bi bi-chevron-compact-left"></i></a>',
			nextArrow:
				'<a class="slick-next"><i class="bi bi-chevron-compact-right"></i></a>',
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 8,
					}
				},
				{
					breakpoint: 768,
					settings: {
						slidesToShow: 4,
					}
				},
				{
					breakpoint: 550,
					settings: {
						slidesToShow: 4,
					}
				}
			]
		});

	});

	// Hide Header on on scroll down
	var delta, didScroll, hasScrolled, lastScrollTop, navbarHeight;
	didScroll = void 0;
	lastScrollTop = 0;
	delta = 5;
	navbarHeight = $('header#site-header').outerHeight();

	hasScrolled = function () {
		var st;
		st = $(window).scrollTop();
		if (Math.abs(lastScrollTop - st) <= delta) {
			return;
		}
		// If they scrolled down and are past the navbar, add class .nav-up.
		// This is necessary so you never see what is "behind" the navbar.
		if (st > lastScrollTop && st > navbarHeight) {
			// Scroll Down
			$('header').removeClass('nav-down').addClass('nav-up');

		} else {
			//Scroll Up
			if (st + $(window).height() < $(document).height()) {
				$('header').removeClass('nav-up').addClass('nav-down');
			}
		}
		lastScrollTop = st;
	};

	$(window).scroll(function (event) {
		didScroll = true;
	});

	setInterval((function () {
		if (didScroll) {
			hasScrolled();
			didScroll = false;
		}
	}), 150);

	$(document).ready(function () {

		// Timeline read more btn
		$('.timeline-readmore').click(function (e) {
			$(this).siblings('.timeline--milestones--item__desc').addClass('open');
			$(this).fadeOut();
		});


		// Show more gallery items
		$('#gallery-show-more').click(function (e) {
			let $count = $(this).closest('.gallery-wrap').data('count');
			//console.log($count);
			$(this).closest('.gallery-wrap').find('.gallery-item:not(:visible):lt(' + $count + ')').removeClass('d-none');
			$(this).closest('.gallery-wrap').data('count', $count + 9);
			if ($(this).closest('.gallery-wrap').data('count') >= $('.gallery-wrap').children().length) {
				$('#gallery-show-more').hide();
			}
		});

		// Add toggle icons to mobile nav 
		$('#menu-primary li.menu-item-has-children > a').append($('<div class="mobile-sub-menu-toggle d-lg-none"><i class="open-icon fa-solid fa-chevron-right"></i></div>'));

		// Toggle mobile nav sub menus
		$('.menu-item-has-children > a ').click(function (e) {
			e.preventDefault();
			$('.menu-item-has-children').not($(this).parent('.menu-item-has-children')).each(function () {
				$(this).removeClass('open');
				return $(this).children('ul.dropdown-menu').slideUp('fast');
			});
			$(this).parent('.menu-item-has-children').children('ul.dropdown-menu').slideToggle('fast');
			return $(this).parent('.menu-item-has-children').toggleClass('open');
		});


	});



	$("#clickme").on("click", function () {
		$("#book").animate({
			opacity: 0.25,
			left: "+=50",
			height: "toggle"
		}, 5000, function () {
			// Animation complete.
		});
	});


	// Home page hero curtain effect 

	// const heroCurtain = $('.home-block__hero-curtain');
	// const featShows = $('.home-block__featured-shows');
	const innerContent = $('.page-content--inner');

	$(document).scroll(function () {
		if ($(window).scrollTop() >= $(window).height()) {
			innerContent.addClass('unlock');

			const topBarHeight = $('.header-top-bar').outerHeight();

			// Fix for jump when announcement banner active #217
			$('.home .page-content--inner.unlock').css('margin-top', (parseInt(- topBarHeight)));
			// Fix for jump when logged in #217
			$('.admin-bar.home .page-content--inner.unlock').css('margin-top', (parseInt(-32 - topBarHeight)));

			AOS.refresh(); // Recalculate AOS
		}
		else {
			innerContent.removeClass('unlock');
			AOS.refresh(); // Recalculate AOS
			$('.home .page-content--inner').css('margin-top', 0);
			$('.admin-bar.home .page-content--inner').css('margin-top', 0);
		}
	});

	// Shrink logo on scroll
	$(document).scroll(function () {
		if ($(window).width() > 1024 && $(window).scrollTop() > 150) {
			$('img.custom-logo').addClass('scrolled');
		} else {
			$('img.custom-logo').removeClass('scrolled');
		}
	});

	// Re-adjust spacing on window resize
	// $(window).resize(announcementBannerSpacing).trigger('resize'); // This just keeps adding the padding to itself

})($);